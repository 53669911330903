import React, { Component } from 'react';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import axios from 'axios';

import * as settings from '../../settings';

class VendorBody extends Component {
    constructor(props) {
        super(props);

        this.readOnly = props.readOnly;
        this.goToMaterials = props.goToMaterials;
        
        this.state = {
            vendorInfo: props.vendorInfo,
            currCellVal:  '',
            updateVendor: props.updateVendor
        } ;
    }

    validateContent = (data) => {
        const jsonRegExp = /\{.*:.*\}/g;
        const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
        if (data.match(jsonRegExp) || data.match(wordRegExp)) {
            this.setState({ inputError: 'Invalid input' });
            return false;
        }
        else return true;
    }

    handleSubmit = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let vendorInfo = this.state.vendorInfo;
                vendorInfo.notes = this.state.currCellVal;
                this.setState({vendorInfo: vendorInfo},
                    function() {
                        axios.put(settings.backend_url + '/vendors',
                            this.state.vendorInfo)
                            .then(res => {
                                this.state.updateVendor(this.state.vendorInfo);
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleChange = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    cickOnMaterialsLink = (e) => {
        e.preventDefault();
        this.goToMaterials(this.state.vendorInfo.vendorName);
    }

    onHover = cell => { this.setState({ hover: cell }); };
    onExitHover = () => { this.setState({ hover: '' }); };
    onClick = (cell, value) => { this.setState({ click: cell, currCellVal: value }); };
    onBlur = (event) => {
        this.setState({ hover: '', click: '' });
        if (this.state.vendorInfo.notes !== this.state.currCellVal)
            this.handleSubmit(event);
    };


    VendorCell = props => {
        if (props.clicked === props.cell && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlur}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChange}
                                        onBlur={this.onBlur} />
                        </Form></Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHover(props.cell)}}
                            onMouseLeave={this.onExitHover}
                            onClick={() => this.onClick(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    render() {
        const cellStyle = {
            false: {background: '#F6F7F7', fontSize: '1.2rem'},
            true: {background: '#DCE0E1', fontSize: '1.2rem'}
        }

        return (
            <Grid columns={2}
                  style={{background: 'white', padding: '2rem 1rem', margin: '0rem'}} >
                <Grid.Column>
                    <Grid.Row style={{paddingBottom: '1rem'}}>
                        <Header as='h2'>INVOLVEMENT</Header></Grid.Row>
                    <Grid.Row>List all materials related to this vendor:</Grid.Row>
                    <Grid.Row style={{textAlign: 'left', textDecoration: 'underline', fontSize: '1.2rem', marginTop: '0.5rem'}}>
                        <a href={'#'} onClick={(e) => 
                            this.cickOnMaterialsLink(e)
                        }>Go to Materials list</a></Grid.Row>
                </Grid.Column>

                <Grid.Column>
                    <Grid.Row className="info">
                        Vendor Notes
                    </Grid.Row>
                    <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                        <this.VendorCell cell='notes' value={this.state.vendorInfo.notes} clicked={this.state.click}
                                    cellStyle={cellStyle[this.state.hover==='notes']} />
                    </Grid.Row>
               </Grid.Column>
            </Grid>
         );
    }
}

export default VendorBody;