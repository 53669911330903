import React, { Component } from 'react';
import { Dropdown, Form, Grid } from 'semantic-ui-react';
import _ from 'lodash'
import axios from 'axios';
import * as jwtDecode from "jwt-decode";

import * as settings from '../../settings';

class MaterialHeader extends Component {
    constructor(props) {
        super(props);

        this.readOnly = props.readOnly;
        this.attributes = props.attributes;
        this.pdivOptions = [];
        for(let i = 0; i < this.attributes.pdivOptions.length; i++) {
            let pdiv = _.clone(this.attributes.pdivOptions[i], true);
            pdiv.value = this.attributes.pdivOptions[i].value.name;
            this.pdivOptions.push(pdiv);
        }

        this.state = {
            materialInfo: props.materialInfo,
            currCellVal:  '',
            updateMaterial: props.updateMaterial
        } ;
    }

    missingRequired = () => {
        return !this.state.currCellVal;
    };

    validateContent = (data) => {
        const jsonRegExp = /\{.*:.*\}/g;
        const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
        if (data.match(jsonRegExp) || data.match(wordRegExp)) {
            this.setState({ inputError: 'Invalid input' });
            return false;
        }
        else return true;
    }

    handleSubmit = (event, cell) => {
        event.preventDefault();
        if (!this.missingRequired()) {
            if (this.validateContent(this.state.currCellVal)) {
                let materialInfo = this.state.materialInfo;
                materialInfo[cell] = this.state.currCellVal;
                this.setState({materialInfo: materialInfo},
                    function() {
                        axios.put(settings.backend_url + '/materials?type=header',
                            this.state.materialInfo)
                            .then(res => {
                                this.state.updateMaterial(this.state.materialInfo);
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleChange = (event, { name, value }) => {
        let accessToken = localStorage.getItem('vcr-accessToken');
        var sessionIdInfo = jwtDecode(accessToken);
        let userMayUpdate = false;
        for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
            if(sessionIdInfo['cognito:groups'][i] === 'vcr-admin') {
                userMayUpdate = true;
                break;
            }
            if(sessionIdInfo['cognito:groups'][i] === 'vcr-edit-create') {
                userMayUpdate = true;
                break;
            }
        }
        if(userMayUpdate) {
            let materialInfo = this.state.materialInfo;
            materialInfo[name] = value;
            this.setState({materialInfo: materialInfo});
            this.setState({ inputError: false });
        }
    };

    onHover = cell => { this.setState({ hover: cell }); };
    onExitHover = () => { this.setState({ hover: '' }); };
    onClick = (cell, value) => { this.setState({ click: cell, currCellVal: value }); };
    onBlur = (event, cell) => {
        this.setState({ hover: '', click: '' });
        if (this.state.materialInfo[cell] !== this.state.currCellVal) {
            this.setState({currCellVal: this.state.materialInfo[cell]}, function() {
                this.handleSubmit(event, cell);
            });
        }
    };

    shortenText(text, length) {
        if(text.length > length) {
            return text.substring(0, length) + '...';
        } else {
            return text;
        }
    }

    MaterialCell = props => {
        if (props.clicked === props.cell && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                <Form onSubmit={(e) => {this.onBlur(e, props.cell)}}>
                    <Form.Input autoFocus                                    
                                size='mini'
                                name={props.cell}
                                defaultValue={props.value}
                                onChange={this.handleChange}
                                onBlur={(e) => {this.onBlur(e, props.cell)}} />
                </Form></Grid.Column> );
        }
        else {
            return ( <Grid.Column
                            onMouseEnter={() => {this.onHover(props.cell)}}
                            onMouseLeave={this.onExitHover}
                            onClick={() => this.onClick(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {this.shortenText(props.value, 30)}
                     </Grid.Column> );
        }
    };

    PdivSelect = props => {
        if (props.clicked === props.cell && !this.readOnly) {
            return ( <Grid.Column style={{height: '1px'}} width={props.width}>
                <Form onSubmit={(e) => {this.onBlur(e, props.cell)}}>
                    <Form.Select name='pdiv' 
                                options={this.pdivOptions}
                                defaultValue={props.value}
                                onChange={this.handleChange}
                                onBlur={(e) => {this.onBlur(e, props.cell)}} />
                </Form></Grid.Column> );
        }
        else {
            return ( <Grid.Column
                            onMouseEnter={() => {this.onHover(props.cell)}}
                            onMouseLeave={this.onExitHover}
                            onClick={() => this.onClick(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                            {props.value}
                        </Grid.Column> );
        }

    };

    PurchaserSelect = props => {
        if (props.clicked === props.cell && !this.readOnly) {
            return ( <Grid.Column style={{height: '1px'}} width={props.width}>
                <Form onSubmit={(e) => {this.onBlur(e, props.cell)}}>
                    <Form.Select fluid name='purchaser'
                        options={this.attributes.purchaserOptions}
                        defaultValue={props.value}
                        onChange={this.handleChange}
                        onBlur={(e) => {this.onBlur(e, props.cell)}} />
                </Form></Grid.Column> );
        }
        else {
            return ( <Grid.Column
                            onMouseEnter={() => {this.onHover(props.cell)}}
                            onMouseLeave={this.onExitHover}
                            onClick={() => this.onClick(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                    </Grid.Column> );
        }
    };

    handleChangeStatusSelect = (event, { value }) => {
        if(!this.readOnly) {
            let materialInfo = this.state.materialInfo;
            materialInfo.materialStatus = value;

            this.setState({materialInfo: materialInfo }, () => {
                axios.put(settings.backend_url + '/materials?type=header',
                    this.state.materialInfo)
                    .then(res => {
                        // pass
                    })
                    .catch(err => {
                        console.error(err);
                    });
            });
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    StatusSelect = () => {
        const options = [
            { key: 'Active', text: 'Active', value: 'Active' },
            { key: 'Inactive', text: 'Inactive', value: 'Inactive' }
        ]
        const currentValue = "Status: " + this.state.materialInfo.materialStatus;
        return (
            <Dropdown className="status-label" onChange={this.handleChangeStatusSelect} text={currentValue} options={options}/>
        );
    }

    render() {
        const cellStyle = {
            false: {background: '#2769da'},
            true: {background: '#0749BC'}
        }

        return (
            <Grid columns={4}
                style={{background: '#2769da', color: 'white', padding: '0rem 1rem', margin: '0rem'}} >
                <Grid.Row style={{paddingBottom: '0.1rem'}} >
                    <Grid.Column>SKF Designation</Grid.Column>
                    <Grid.Column>Factory</Grid.Column>
                    <Grid.Column>Purchaser</Grid.Column>
                    <Grid.Column/>
                </Grid.Row>

                <Grid.Row style={{padding: '0.1rem 0rem 0.5rem 0rem', height: '2.5rem', fontSize: '1.2rem'}} >
                    <this.MaterialCell cell='materialDesignation' value={this.state.materialInfo.materialDesignation} clicked={this.state.click}
                        cellStyle={cellStyle[this.state.hover==='materialDesignation']} />
                    <this.PdivSelect cell='pdiv' value={this.state.materialInfo.pdiv} clicked={this.state.click}
                        cellStyle={cellStyle[this.state.hover==='pdiv']} />
                    <this.PurchaserSelect cell='purchaser' value={this.state.materialInfo.purchaser} clicked={this.state.click}
                        cellStyle={cellStyle[this.state.hover==='purchaser']} />
                    <Grid.Column>
                        <div style={{float: 'right'}}>
                            
                            <div className="status-div">
                                <this.StatusSelect/>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
         );
    }
}
export default MaterialHeader;