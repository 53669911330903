import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import axios from 'axios';
import * as jwtDecode from "jwt-decode";

import * as settings from '../../settings';

class VendorHeader extends Component {
    constructor(props) {
        super(props);

        this.readOnly = props.readOnly;

        this.state = {
            vendorInfo: props.vendorInfo,
            currCellVal:  '',
            updateVendor: props.updateVendor
        } ;
    }

    missingRequired = () => {
        return !this.state.currCellVal;
    };

    validateContent = (data) => {
        const jsonRegExp = /\{.*:.*\}/g;
        const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
        if (data.match(jsonRegExp) || data.match(wordRegExp)) {
            this.setState({ inputError: 'Invalid input' });
            return false;
        }
        else return true;
    }

    handleSubmit = (event, cell) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (!this.missingRequired()) {
                if (this.validateContent(this.state.currCellVal)) {
                    let vendorInfo = this.state.vendorInfo;
                    vendorInfo[cell] = this.state.currCellVal;
                    this.setState({vendorInfo: vendorInfo},
                        function() {
                            axios.put(settings.backend_url + '/vendors',
                                this.state.vendorInfo)
                                .then(res => {
                                    this.state.updateVendor(this.state.vendorInfo);
                                })
                                .catch(err => {
                                    console.error(err);
                                });
                        });
                }
                            
            }
        }
    };

    handleChange = (event, { name, value }) => {
        if(!this.readOnly) {
            let accessToken = localStorage.getItem('vcr-accessToken');
            var sessionIdInfo = jwtDecode(accessToken);
            let userMayUpdate = false;
            for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
                if(sessionIdInfo['cognito:groups'][i] === 'vcr-admin') {
                    userMayUpdate = true;
                    break;
                }
                if(sessionIdInfo['cognito:groups'][i] === 'vcr-edit-create') {
                    userMayUpdate = true;
                    break;
                }
            }
            if(userMayUpdate) {
                let vendorInfo = this.state.vendorInfo;
                vendorInfo[name] = value;
                this.setState({vendorInfo: vendorInfo});
                this.setState({ inputError: false });
            }
        }
    };

    onHover = cell => { this.setState({ hover: cell }); };
    onExitHover = () => { this.setState({ hover: '' }); };
    onClick = (cell, value) => { this.setState({ click: cell, currCellVal: value }); };
    onBlur = (event, cell) => {
        this.setState({ hover: '', click: '' });
        if (this.state.vendorInfo[cell] !== this.state.currCellVal) {
            this.setState({currCellVal: this.state.vendorInfo[cell]}, function() {
                this.handleSubmit(event, cell);
            });
        }
    };

    shortenText(text, length) {
        if(text.length > length) {
            return text.substring(0, length) + '...';
        } else {
            return text;
        }
    }

    VendorCell = props => {
        if (props.clicked === props.cell && !this.readOnly) {
            if (props.type === 'radio') {
                return ( <Grid.Column width={props.width} floated='right'>
                            <Form inverted><Form.Group onBlur={(e) => {this.onBlur(e, props.cell)}}>
                                <Form.Radio label='Yes' value='Yes' name='internalVendor'
                                            checked={props.value === 'Yes'}
                                            onChange={this.handleChange} />
                                <Form.Radio label='No' value='No' name='internalVendor'
                                            checked={props.value === 'No'}
                                            onChange={this.handleChange} />
                            </Form.Group></Form></Grid.Column> );
            }
            else {
                return ( <Grid.Column width={props.width}>
                            <Form onSubmit={(e) => {this.onBlur(e, props.cell)}}>
                                <Form.Input autoFocus
                                            size='mini'
                                            name={props.cell}
                                            defaultValue={props.value}
                                            onChange={this.handleChange}
                                            onBlur={(e) => {this.onBlur(e, props.cell)}} />
                            </Form></Grid.Column> );
            }
        }
        else {
            return ( <Grid.Column
                            onMouseEnter={() => {this.onHover(props.cell)}}
                            onMouseLeave={this.onExitHover}
                            onClick={() => this.onClick(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {this.shortenText(props.value, 30)}
                     </Grid.Column> );
        }
    };


    render() {
        const cellStyle = {
            false: {background: '#2769da'},
            true: {background: '#0749BC'}
        }

        return (
            <Grid columns={4}
                style={{background: '#2769da', color: 'white', padding: '0rem 1rem', margin: '0rem'}} >
                <Grid.Row style={{paddingBottom: '0.1rem'}} >
                    <Grid.Column>Name</Grid.Column>
                    <Grid.Column>Location</Grid.Column>
                    <Grid.Column width={6}>Description</Grid.Column>
                    <Grid.Column floated='right' width={2}>Internal</Grid.Column>
                </Grid.Row>

                <Grid.Row style={{padding: '0.1rem 0rem 0.5rem 0rem', height: '2.5rem', fontSize: '1.2rem'}} >
                    <this.VendorCell cell='vendorName' value={this.state.vendorInfo.vendorName} clicked={this.state.click}
                        cellStyle={cellStyle[this.state.hover==='vendorName']} />
                    <this.VendorCell cell='vendorLocation' value={this.state.vendorInfo.vendorLocation} clicked={this.state.click}
                        cellStyle={cellStyle[this.state.hover==='vendorLocation']} />
                    <this.VendorCell cell='description' value={this.state.vendorInfo.description} clicked={this.state.click}
                        cellStyle={cellStyle[this.state.hover==='description']} width={6} />
                    <this.VendorCell cell='internalVendor' value={this.state.vendorInfo.internalVendor} clicked={this.state.click}
                        cellStyle={cellStyle[this.state.hover==='internalVendor']} width={2} type={'radio'} />
                </Grid.Row>
            </Grid>
         );
    }
}

export default VendorHeader;