import React, { Component } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react'
import axios from 'axios';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import * as settings from '../../../../settings';

class CreateEvidenceModal extends Component {

    constructor(props) {
        super(props);

        this.materialId = props.materialId;
        this.getEvidence = props.getEvidence;

        this.stateOptions = [
            {key: 'Evidence requested', text: 'Evidence requested', value: 'Evidence requested'},
            {key: 'Evidence received', text: 'Evidence received', value: 'Evidence received'}
        ]
        
        this.initialState = {
            evidenceStatus: '',
            evidenceType: '',
            evidenceNotes: '',
            dateRequired: null,
            notesError: false
        };

        this.state = this.initialState;
    }

    missingRequired = () => {
        return (
            !this.state.evidenceStatus || !this.state.evidenceType
        );
    };

    validateContent = (data, error) => {
        const jsonRegExp = /\{.*:.*\}/g;
        const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
        if (data.match(jsonRegExp) || data.match(wordRegExp)) {
            this.setState({ [error]: 'Invalid input' });
            return false;
        }
        else return true;
      }

      handleSubmit = (event) => {
        let dateSelected = ' ';
        if(this.state.dateRequired) {
            if(this.state.dateRequired) {
                let d = new Date(this.state.dateRequired);
                dateSelected = d.getMonth()+1 + '/' + d.getDate() + '/' + d.getFullYear();
            }
        }
        event.preventDefault();
        if (!this.missingRequired()) {
            let evidenceNotes = this.state.evidenceNotes;
            if(!evidenceNotes) {
                evidenceNotes = 'Additional evidence notes'
            }
            axios.post(settings.backend_url + '/evidence/',
                {
                    dateRequired: dateSelected,
                    dateRequested: ' ',
                    dateReceived: ' ',
                    dateApproved: ' ',
                    evidenceNotes: evidenceNotes,
                    evidenceType: this.state.evidenceType,
                    material: this.materialId,
                    evidenceStatus: this.state.evidenceStatus,
                    complianceNotes: 'Additional compliance notes',
                    reachNotes: ' ',
                    rohsNotes: ' ',
                    reach: 'Pending',
                    rohs: 'Pending',
                    declarable: 'Pending',
                    prohibited: 'Pending',
                    approver: ' '
                })
                .then(res => {
                    this.props.closeModal();
                    this.getEvidence();
                })
                .catch(err => {
                    console.error(err);
                });
        }
      };

    handleChange = (event, { name, value }) => {
        this.setState({ [name]: value });
        const error = name + 'Error';
        this.setState({ [error]: false });

    };

    onDateChange = date => {
        this.setState({
            dateRequired: date
        });
    };
 
    render() {
        const CustomInput = ({ value, onClick }) => {
            if(!this.state.dateRequired) {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    Select Date
                </Button>
            } else {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    {value}
                </Button>
            }
        };

        const FORMAT = 'MM/dd/yyyy';
        return (
            <Modal
            open={this.props.isOpen}
            onClose={this.props.closeModal}>
            <Modal.Header>CREATE EVIDENCE</Modal.Header>

            <Modal.Content>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Select fluid required search label='State' name='evidenceStatus' options={this.stateOptions}
                                    placeholder='Select State'
                                    onChange={this.handleChange} error={this.state.stateError}/>
                        <Form.Input fluid required label='Evidence Type' name='evidenceType' placeholder='Enter Evidence Type'
                                    onChange={this.handleChange}  error={this.state.evidenceTypeError} />
                    </Form.Group>
                    <Form.TextArea style={{ fontFamily: 'SKF-Chevin' }} label='Evidence Notes' name='evidenceNotes'
                                    placeholder='Enter notes about the evidence'
                                    onChange={this.handleChange} />
                    <div>
                        <label>Date Requested</label>
                    </div>
                    <div style={{paddingTop: '0.5rem'}}>
                        <DatePicker
                            selected={this.state.dateRequested}
                            onChange={date => this.onDateChange(date)}
                            customInput={<CustomInput />}
                            dateFormat={FORMAT}
                            placeholderText="Click to select a date"
                        />
                    </div>
                </Form>
            </Modal.Content>

            <Modal.Actions>
              <Button basic
                onClick={this.props.closeModal}
                content='Cancel'
              />
              <Button
                content='Create'
                type='submit'
                onClick={this.handleSubmit}
                disabled={this.missingRequired()}
              />
            </Modal.Actions>

            </Modal>
        );
    }
}

export default CreateEvidenceModal;