import React, {Component} from 'react';
import VendorHeader from './VendorHeader';
import VendorBody from './VendorBody';
import { Header } from 'semantic-ui-react';

class Vendor extends Component {
    constructor(props) {
        super(props);

        this.readOnly = props.readOnly;
        this.goToMaterials = props.goToMaterials;
        
        let vendor = props.vendor;
        if(!vendor.notes) {
            vendor['notes'] = 'Additional vendor notes';
        }

        this.state = {
            vendor: vendor,
            changeView: props.changeView,
            updateVendor: props.updateVendor
        };
    };

    render() {
        return (
            <div>
                <Header as='h1' className="cursor-pointer" onClick={() => this.state.changeView(null)}>
                    <img style={{width: '0.9rem', verticalAlign: 'inherit'}}
                            src="images/arrow-left.png" alt='left arrow'/>
                    BACK TO VENDORS</Header>
                <VendorHeader readOnly={this.readOnly} vendorInfo={this.state.vendor} updateVendor={this.state.updateVendor}/>
                <VendorBody readOnly={this.readOnly} goToMaterials={this.goToMaterials} vendorInfo={this.state.vendor} updateVendor={this.state.updateVendor}/>
            </div>
        );
    }
}

export default Vendor;
