import React, { Component } from 'react';
import CreateEvidenceModal from './modals/CreateEvidenceModal';
import Storage from './Storage';
import { Button, Dropdown, Form, Grid, Header, Segment } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import * as jwtDecode from "jwt-decode";

import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import * as settings from '../../../settings';

// TODO: REFACTOR! REFACTOR! REFACTOR!
// Combine similar functions, do not keep copies of almost identical functions.
// Split in to several js files. Clean up!
class Evidence extends Component {
    constructor(props) {
        super(props);

        this.materialId = props.materialId

        this.admin = false;
        this.editCreate = false;
        this.approver = false;
        this.readOnly = false;

        this.accessToken = localStorage.getItem('vcr-accessToken');
        var sessionIdInfo = jwtDecode(this.accessToken);
        for(let i = 0; i < sessionIdInfo['cognito:groups'].length; i++) {
            if(sessionIdInfo['cognito:groups'][i] === 'vcr-admin') {
                this.admin = true;
            } else if(sessionIdInfo['cognito:groups'][i] === 'vcr-approver') {
                this.approver = true;
            } else if(sessionIdInfo['cognito:groups'][i] === 'vcr-edit-create') {
                this.editCreate = true;
            } else if(sessionIdInfo['cognito:groups'][i] === 'vcr-read-only') {
                this.readOnly = true;
            }
        }

        this.state = {
            evidenceInfo: {
                reach: false,
                rohs: false,
                declarable: false,
                prohibited: false
            },
            evidenceModalOpen: false,
            view: this.renderLoadingEvidence(),
            currCellVal:  '',
            files: [],
            reach: 'images/reach_deselected.png',
            rohs: 'images/rohs_deselected.png',
            declarable: 'images/declarable_pending.png',
            prohibited: 'images/prohibited_pending.png'
        };
        this.storage = new Storage();
    }

    componentDidMount = () => {
        this.getEvidence();
    }

    openModal = (modal) =>
    {
        if (modal === "evidence") {
            this.setState({ evidenceModalOpen: true });
        }
    }
    closeModal = () =>
    {
        this.setState({
            evidenceModalOpen: false,
        });
    }

    setReach = () => {
        if(!this.readOnly) {
            if(this.state.evidenceInfo.reach === false) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.reach = 'Not compliant';
                
                this.setState({evidenceInfo: evidenceInfo, reach: 'images/reach_deselected.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.reach === true) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.reach = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, reach: 'images/reach_deselected.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.reach === 'Pending') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.reach = 'Not compliant';
                
                this.setState({evidenceInfo: evidenceInfo}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.reach === 'Not compliant') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.reach = 'Compliant';
                
                this.setState({evidenceInfo: evidenceInfo, reach: 'images/reach.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.reach === 'Compliant') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.reach = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, reach: 'images/reach_deselected.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            }
        }
    }

    setRohs = () => {
        if(!this.readOnly) {
            if(this.state.evidenceInfo.rohs === false) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.rohs = 'Not compliant';
                
                this.setState({evidenceInfo: evidenceInfo, rohs: 'images/rohs_deselected.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.rohs === true) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.rohs = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, rohs: 'images/rohs_deselected.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.rohs === 'Pending') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.rohs = 'Not compliant';
                
                this.setState({evidenceInfo: evidenceInfo}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.rohs === 'Not compliant') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.rohs = 'Compliant';
                
                this.setState({evidenceInfo: evidenceInfo, rohs: 'images/rohs.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.rohs === 'Compliant') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.rohs = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, rohs: 'images/rohs_deselected.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            }
        }
    }

    setDeclarable = () => {
        if(!this.readOnly) {
            if(this.state.evidenceInfo.declarable === false) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.declarable = 'No declarable substances';
                
                this.setState({evidenceInfo: evidenceInfo, declarable: 'images/declarable.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.declarable === true) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.declarable = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, declarable: 'images/declarable_pending.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.declarable === 'Pending') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.declarable = 'No declarable substances';
                
                this.setState({evidenceInfo: evidenceInfo}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.declarable === 'No declarable substances') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.declarable = 'Declarable substances present';
                
                this.setState({evidenceInfo: evidenceInfo, declarable: 'images/declarable.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.declarable === 'Declarable substances present') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.declarable = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, declarable: 'images/declarable_pending.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            }
        }
    }

    setProhibited = () => {
        if(!this.readOnly) {
            if(this.state.evidenceInfo.prohibited === false) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.prohibited = 'No prohibited substances';
                
                this.setState({evidenceInfo: evidenceInfo, prohibited: 'images/prohibited.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.prohibited === true) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.prohibited = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, prohibited: 'images/prohibited_pending.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.prohibited === 'Pending') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.prohibited = 'No prohibited substances';
                
                this.setState({evidenceInfo: evidenceInfo}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.prohibited === 'No prohibited substances') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.prohibited = 'Prohibited substances present';
                
                this.setState({evidenceInfo: evidenceInfo, prohibited: 'images/prohibited.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            } else if(this.state.evidenceInfo.prohibited === 'Prohibited substances present') {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.prohibited = 'Pending';
                
                this.setState({evidenceInfo: evidenceInfo, prohibited: 'images/prohibited_pending.png'}, () => {
                    axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                });
            }
        }
    }
    
    uploadFile = (event) => {
        if(!this.readOnly) {
            for(let i = 0; i < event.target.files.length; i++) {
                let fileName = this.materialId + '_' + this.getFileName(event.target.files[i].name);
                this.storage.uploadFile({
                    name: fileName,
                    content: event.target.files[i]
                }).then(() => {
                    this.getEvidence();
                })
            }
        }
    }

    getFileName = (path) => {
        if(path.indexOf('/') > -1) {
            return path.substring(path.lastIndexOf('/')+1, path.length);
        } else if(path.indexOf('\\') > -1) {
            return path.substring(path.lastIndexOf('\\')+1, path.length);
        } else {
            return path;
        }
    }

    getFileEnding = (file) => {
        return file.substring(file.lastIndexOf('.'), file.length);
    }

    shortenName = (file) => {
        if(file.lastIndexOf('.') > 15) {
            return file.substring(0, 15) + '..' + this.getFileEnding(file);
        } else {
            return file;
        }
    }

    downloadFile = (file) => {
        this.storage.downloadFile(this.materialId, file);
    }
    
    getEvidence = () => {
        var self = this;
        axios.get(settings.backend_url + '/evidence/' + self.materialId)
            .then(res => {
                if(res.data.data != null) {
                    self.state.files.splice(0, self.state.files.length);
                    let fileList = [];
                    self.storage.listFiles(self.materialId, (err, files) => {
                        if(err) {
                            console.error(err);
                        } else {
                            for(let i = 0; i < files.length; i++) {
                                fileList.push(files[i]);
                            }
                            let reach;
                            let rohs;
                            if(res.data.data.reach !== 'Compliant') {
                                reach = 'images/reach_deselected.png';
                            } else {
                                reach = 'images/reach.png';
                            }
                            if(res.data.data.rohs !== 'Compliant') {
                                rohs = 'images/rohs_deselected.png';
                            } else {
                                rohs = 'images/rohs.png';
                            }
                            self.setState({ files: fileList, evidenceInfo: res.data.data, reach: reach, rohs: rohs }, () => {
                                self.setState({view: self.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false)});
                            });
                        }
                    });
                } else {
                    self.setState({view: self.renderNoEvidence()});
                }
            })
            .catch(err => {
                self.setState({view: self.renderNoEvidence()});
                console.error(err);
            });
    }

    validateContent = (data) => {
        const jsonRegExp = /\{.*:.*\}/g;
        const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
        if (data.match(jsonRegExp) || data.match(wordRegExp)) {
            this.setState({ inputError: 'Invalid input' });
            return false;
        }
        else return true;
    }

    handleSubmitEvidence = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.evidenceNotes = this.state.currCellVal;
                this.setState({ evidenceInfo: evidenceInfo },
                    function() {
                        axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleSubmitCompliance = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.complianceNotes = this.state.currCellVal;
                this.setState({ evidenceInfo: evidenceInfo },
                    function() {
                        axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleSubmitReach = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.reachNotes = this.state.currCellVal;
                this.setState({ evidenceInfo: evidenceInfo },
                    function() {
                        axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleSubmitRohs = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.rohsNotes = this.state.currCellVal;
                this.setState({ evidenceInfo: evidenceInfo },
                    function() {
                        axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleSubmitDeclarable = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.declarableNotes = this.state.currCellVal;
                this.setState({ evidenceInfo: evidenceInfo },
                    function() {
                        axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleSubmitProhibited = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let evidenceInfo = this.state.evidenceInfo;
                evidenceInfo.prohibitedNotes = this.state.currCellVal;
                this.setState({ evidenceInfo: evidenceInfo },
                    function() {
                        axios.put(settings.backend_url + '/evidence',
                            this.state.evidenceInfo)
                            .then(res => {
                                this.getEvidence();
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleChangeEvidence = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    handleChangeCompliance = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    handleChangeReach = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    handleChangeRohs = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    handleChangeDeclarable = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    handleChangeProhibited = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    approve = () => {
        var sessionIdInfo = jwtDecode(this.accessToken);
        let evidenceInfo = this.state.evidenceInfo;
        evidenceInfo.approver = sessionIdInfo.username;

        let d = new Date();
        let dateSelected = d.getMonth()+1 + '/' + d.getDate() + '/' + d.getFullYear();
        evidenceInfo.dateApproved = dateSelected;

        this.setState({evidenceInfo: evidenceInfo, view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }, () => {
            axios.put(settings.backend_url + '/evidence',
                this.state.evidenceInfo)
                .then(res => {
                    // pass
                })
                .catch(err => {
                    console.error(err);
                });
        });
    }

    handleChangeStatusSelect = (event, { value }) => {
        let mayUpdate = false;
        if(this.approver || this.admin) {
            mayUpdate = true;
        } else {
            if(this.state.evidenceInfo.evidenceStatus !== 'Evidence approved' && this.editCreate && value !== 'Evidence approved') {
                mayUpdate = true;
            }
        }
        if(mayUpdate) {
            let evidenceInfo = this.state.evidenceInfo;
            evidenceInfo.evidenceStatus = value;
            if(value === 'Evidence approved') {
                this.approve();
            } else {
                evidenceInfo.dateApproved = ' ';
                evidenceInfo.approver = ' ';
            }
            this.setState({evidenceInfo: evidenceInfo, view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }, () => {
                axios.put(settings.backend_url + '/evidence',
                    this.state.evidenceInfo)
                    .then(res => {
                        // pass
                    })
                    .catch(err => {
                        console.error(err);
                    });
            });
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    handleChangeDateRequired = (date, field) => {
        if(!this.readOnly) {
            let d = new Date(date);
            let dateSelected = d.getMonth()+1 + '/' + d.getDate() + '/' + d.getFullYear();

            let evidenceInfo = this.state.evidenceInfo;
            evidenceInfo[field] = dateSelected;
            this.setState({evidenceInfo: evidenceInfo, view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }, () => {
                axios.put(settings.backend_url + '/evidence',
                    this.state.evidenceInfo)
                    .then(res => {
                    })
                    .catch(err => {
                        console.error(err);
                    });
            });
        }
    };

    onHoverEvidence = cell => { this.setState({ hover: cell, view: this.renderEvidence(true, false, false, false, false, false, false, false, false, false, false, false)}); };
    onExitHoverEvidence = () => { this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }); };
    onClickEvidence = (cell, value) => { this.setState({ currCellVal: value, view: this.renderEvidence(false, true, false, false, false, false, false, false) }); };
    onBlurEvidence = (event) => {
        this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false) });
        if (this.state.evidenceInfo.evidenceNotes !== this.state.currCellVal)
            this.handleSubmitEvidence(event);
    };

    onHoverCompliance = cell => { this.setState({ hover: cell, view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false)}); };
    onExitHoverCompliance = () => { this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }); };
    onClickCompliance = (cell, value) => { this.setState({ currCellVal: value, view: this.renderEvidence(false, false, false, true, false, false, false, false, false, false, false, false) }); };
    onBlurCompliance = (event) => {
        this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) });
        if (this.state.evidenceInfo.complianceNotes !== this.state.currCellVal) {
            this.handleSubmitCompliance(event);
        }
    };

    onHoverReach = cell => { this.setState({ hover: cell, view: this.renderEvidence(false, false, false, false, true, false, false, false, false, false, false, false)}); };
    onExitHoverReach = () => { this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }); };
    onClickReach = (cell, value) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value, view: this.renderEvidence(false, false, false, false, false, true, false, false, false, false, false, false) });
        }
    };
    onBlurReach = (event) => {
        this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) });
        if (this.state.evidenceInfo.reachNotes !== this.state.currCellVal && !this.readOnly) {
            this.handleSubmitReach(event);
        }
    };

    onHoverRohs = cell => { this.setState({ hover: cell, view: this.renderEvidence(false, false, false, false, false, false, false, true, false, false, false, false)}); };
    onExitHoverRohs = () => { this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }); };
    onClickRohs = (cell, value) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value, view: this.renderEvidence(false, false, false, false, false, false, true, false, false, false, false, false) });
        }
    };
    onBlurRohs = (event) => {
        this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) });
        if (this.state.evidenceInfo.rohsNotes !== this.state.currCellVal && !this.readOnly)
            this.handleSubmitRohs(event);
    };

    onHoverDeclarable = cell => { this.setState({ hover: cell, view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, true, false)}); };
    onExitHoverDeclarable = () => { this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }); };
    onClickDeclarable = (cell, value) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value, view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, true) });
        }
    };
    onBlurDeclarable = (event) => {
        this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) });
        if (this.state.evidenceInfo.declarableNotes !== this.state.currCellVal && !this.readOnly)
            this.handleSubmitDeclarable(event);
    };

    onHoverProhibited = cell => { this.setState({ hover: cell, view: this.renderEvidence(false, false, false, false, false, false, false, false, true)}); };
    onExitHoverProhibited = () => { this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) }); };
    onClickProhibited = (cell, value) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value, view: this.renderEvidence(false, false, false, false, false, false, false, false, false, true, false, false) });
        }
    };
    onBlurProhibited = (event) => {
        this.setState({ hover: '', view: this.renderEvidence(false, false, false, false, false, false, false, false, false, false, false, false) });
        if (this.state.evidenceInfo.prohibitedNotes !== this.state.currCellVal && !this.readOnly)
            this.handleSubmitProhibited(event);
    };

    EvidenceCell = props => {
        if (props.clicked && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlurEvidence}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChangeEvidence}
                                        onBlur={this.onBlurEvidence} />
                        </Form>
                    </Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHoverEvidence(props.cell)}}
                            onMouseLeave={this.onExitHoverEvidence}
                            onClick={() => this.onClickEvidence(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    ComplianceCell = props => {
        if (props.clicked && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlurCompliance}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChangeCompliance}
                                        onBlur={this.onBlurCompliance} />
                        </Form>
                    </Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHoverCompliance(props.cell)}}
                            onMouseLeave={this.onExitHoverCompliance}
                            onClick={() => this.onClickCompliance(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    ReachCell = props => {
        if (props.clicked && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlurReach}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChangeReach}
                                        onBlur={this.onBlurReach} />
                        </Form>
                    </Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHoverReach(props.cell)}}
                            onMouseLeave={this.onExitHoverReach}
                            onClick={() => this.onClickReach(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    RohsCell = props => {
        if (props.clicked && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlurRohs}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChangeRohs}
                                        onBlur={this.onBlurRohs} />
                        </Form>
                    </Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHoverRohs(props.cell)}}
                            onMouseLeave={this.onExitHoverRohs}
                            onClick={() => this.onClickRohs(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    DeclarableCell = props => {
        if (props.clicked && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlurDeclarable}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChangeDeclarable}
                                        onBlur={this.onBlurDeclarable} />
                        </Form>
                    </Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHoverDeclarable(props.cell)}}
                            onMouseLeave={this.onExitHoverDeclarable}
                            onClick={() => this.onClickDeclarable(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    ProhibitedCell = props => {
        if (props.clicked && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlurProhibited}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChangeProhibited}
                                        onBlur={this.onBlurProhibited} />
                        </Form>
                    </Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHoverProhibited(props.cell)}}
                            onMouseLeave={this.onExitHoverProhibited}
                            onClick={() => this.onClickProhibited(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    isReachCompliant() {
        if(this.state.evidenceInfo.reach === 'Compliant') {
            if(this.state.evidenceInfo.reachNotes.length > 1) {
                return 'Compliant with SVHCs';
            } else {
                return this.state.evidenceInfo.reach;
            }
        } else {
            return this.state.evidenceInfo.reach;
        }
    }

    isRohsCompliant() {
        if(this.state.evidenceInfo.rohs === 'Compliant') {
            if(this.state.evidenceInfo.rohsNotes.length > 1) {
                return 'Compliant with exemptions';
            } else {
                return this.state.evidenceInfo.rohs;
            }
        } else {
            return this.state.evidenceInfo.rohs;
        }
    }

    isDeclarable() {
        if(this.state.evidenceInfo.declarable === ' ') {
            return 'Pending';
        }

        if(this.state.evidenceInfo.declarable === true) {
            return 'Declarable substances present';
        }

        if(this.state.evidenceInfo.declarable === false) {
            return 'Pending';
        }

        return this.state.evidenceInfo.declarable;
    }

    isProhibited() {
        if(this.state.evidenceInfo.prohibited === ' ') {
            return 'Pending';
        }

        if(this.state.evidenceInfo.prohibited === true) {
            return 'Prohibited substances present';
        }

        if(this.state.evidenceInfo.prohibited === false) {
            return 'Pending';
        }

        return this.state.evidenceInfo.prohibited;
    }

    declarablePaddingTop = () => {
        return {marginTop: '-0.6rem'};
    }

    prohibitedPaddingTop = () => {
        return {marginTop: '-0.6rem'};
    }

    StatusSelect = () => {
        var options;
        options = [
            { key: 'Evidence required', text: 'Evidence required', value: 'Evidence required' },
            { key: 'Evidence received', text: 'Evidence received', value: 'Evidence received' },
            { key: 'Evidence approved', text: 'Evidence approved', value: 'Evidence approved' }
        ];
        const currentValue = "Status: " + this.state.evidenceInfo.evidenceStatus;
        return (
            <Dropdown className="status-label" onChange={this.handleChangeStatusSelect} text={currentValue} options={options}/>
        );
    }

    DateSelect = (props) => {
        const CustomInput = ({ value, onClick }) => {
            if(!this.state.evidenceInfo[props.field] || this.state.evidenceInfo[props.field] === ' ') {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    Select Date
                </Button>
            } else {
                return <Button onClick={onClick} style={{width: '8.1rem', height: '2.6rem'}}>
                    {value}
                </Button>
            }
        };
        const FORMAT = 'MM/dd/yyyy';
        if(this.state.evidenceInfo[props.field] && this.state.evidenceInfo[props.field] !== ' ') {

            return (
                <DatePicker
                    selected={new Date(this.state.evidenceInfo[props.field])}
                    onChange={date => this.handleChangeDateRequired(date, props.field)}
                    customInput={<CustomInput />}
                    dateFormat={FORMAT}
                    placeholderText="Click to select a date"
                />
            );
        } else {
            return (
                <DatePicker
                    selected={new Date()}
                    onChange={date => this.handleChangeDateRequired(date, props.field)}
                    customInput={<CustomInput />}
                    dateFormat={FORMAT}
                    placeholderText="Click to select a date"
                />
            );
        }
    }

    renderEvidence (
        evidenceNotesHover,
        evidenceNotesClick,
        complianceNotesHover,
        complianceNotesClick,
        reachNotesHover,
        reachNotesClick,
        rohsNotesHover,
        rohsNotesClick,
        prohibitedNotesHover,
        prohibitedNotesClick,
        declarableNotesHover,
        declarableNotesClick
    ) {
        const cellStyle = {
            false: {background: '#F6F7F7', fontSize: '1.2rem'},
            true: {background: '#DCE0E1', fontSize: '1.2rem'}
        }

        const topMargin = {
            false: '-3.5rem',
            true: '-1.6rem'
        }

        return (
            <div style={{background: 'white', marginBottom: '4rem'}}>
                <Grid columns={2} style={{padding: '2rem 1rem', margin: '0rem'}}>
                    <Grid columns={3} style={{width:'70%'}}>
                        <Grid.Row >
                            <Grid.Column style={{width: '8rem'}}><Header as='h2'>EVIDENCE</Header></Grid.Column>
                            <Grid.Column>    
                                <div style={{float: 'left'}}>
                                    <div className="evidence-status-div">
                                        <this.StatusSelect/>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid style={{width: '35%'}}>
                        <Grid.Column>
                            <Grid.Row className="info">
                                Evidence Notes
                            </Grid.Row>
                            <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                                <this.EvidenceCell cell='evidenceNotes' value={this.state.evidenceInfo.evidenceNotes} clicked={evidenceNotesClick}
                                    cellStyle={cellStyle[evidenceNotesHover]} />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>

                    <Grid columns={5} style={{width:'70%', marginTop: topMargin[evidenceNotesClick]}}>
                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column>Date Expired</Grid.Column>
                            <Grid.Column>Date Requested</Grid.Column>
                            <Grid.Column>Date Received</Grid.Column>
                            <Grid.Column>Date Approved</Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="info-label" style={{paddingTop: '0.1rem'}}>
                            <Grid.Column><this.DateSelect field={'dateRequired'}/></Grid.Column>
                            <Grid.Column><this.DateSelect field={'dateRequested'}/></Grid.Column>
                            <Grid.Column><this.DateSelect field={'dateReceived'}/></Grid.Column>
                            <Grid.Column>{this.state.evidenceInfo.dateApproved}</Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column>Evidence Type</Grid.Column>
                            <Grid.Column>Approver</Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="info-label" style={{paddingTop: '0.1rem'}}>
                            <Grid.Column>{this.state.evidenceInfo.evidenceType}</Grid.Column>
                            <Grid.Column>{this.state.evidenceInfo.approver}</Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid>

                <Grid columns={3} style={{padding: '2rem 1rem', margin: '0rem'}}>
                    <Grid style={{width:'70%'}}>
                        <Grid.Column style={{width: '15rem'}}><Header as='h2'>STATUS OF COMPLIANCE</Header></Grid.Column>
                    </Grid>

                    <Grid columns={2} style={{width:'70%'}}>
                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column>REACH Compliance</Grid.Column>
                            <Grid.Column>ROHS Compliance</Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid style={{width: '35%'}}>
                        <Grid.Column>
                            <Grid.Row className="info">
                                Compliance Notes
                            </Grid.Row>
                            <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                                <this.ComplianceCell cell='complianceNotes' value={this.state.evidenceInfo.complianceNotes} clicked={complianceNotesClick}
                                    cellStyle={cellStyle[complianceNotesHover]} />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>

                    <Grid columns={2} style={{width:'70%', marginTop: topMargin[complianceNotesClick]}}>
                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column style={{marginTop: '-2rem'}}>
                                <p style={{color: 'black'}}>
                                    {this.isReachCompliant()}
                                </p>
                                <img className="cursor-pointer" src={this.state.reach} alt='' onClick={() => this.setReach()}/>
                            </Grid.Column>
                            <Grid.Column style={{marginTop: '-2rem'}}>
                                <p style={{color: 'black'}}>
                                    {this.isRohsCompliant()}
                                </p>
                                <img className="cursor-pointer" src={this.state.rohs} alt='' onClick={() => this.setRohs()}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid columns={2} style={{width:'70%'}}>
                        <Grid.Column>
                            <Grid.Row className="info">
                                Notes
                            </Grid.Row>
                            <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                                <this.ReachCell cell='reachNotes' value={this.state.evidenceInfo.reachNotes} clicked={reachNotesClick}
                                    cellStyle={cellStyle[reachNotesHover]} />
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid.Row className="info">
                                Notes
                            </Grid.Row>
                            <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                                <this.RohsCell cell='rohsNotes' value={this.state.evidenceInfo.rohsNotes} clicked={rohsNotesClick}
                                    cellStyle={cellStyle[rohsNotesHover]} />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Grid>

                <Grid columns={3} style={{padding: '0rem 1rem', margin: '0rem'}}>
                    <Grid columns={2} style={{width:'70%'}}>
                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column>Declarable Substances</Grid.Column>
                            <Grid.Column>Prohibited Substances</Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid columns={2} style={{width:'70%', marginTop: '1.6rem'}}>
                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column style={this.declarablePaddingTop()}>
                                <p style={{color: 'black'}}>
                                    {this.isDeclarable()}
                                </p>
                                <img className="cursor-pointer" src={this.state.declarable} alt='' onClick={() => this.setDeclarable()}/>
                            </Grid.Column>
                            <Grid.Column style={this.prohibitedPaddingTop()}>
                                <p style={{color: 'black'}}>
                                    {this.isProhibited()}
                                </p>
                                <img className="cursor-pointer" src={this.state.prohibited} alt='' onClick={() => this.setProhibited()}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Grid columns={2} style={{width:'70%'}}>
                        <Grid.Column>
                            <Grid.Row className="info">
                                Notes
                            </Grid.Row>
                            <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                                <this.DeclarableCell cell='declarableNotes' value={this.state.evidenceInfo.declarableNotes} clicked={declarableNotesClick}
                                    cellStyle={cellStyle[declarableNotesHover]} />
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid.Row className="info">
                                Notes
                            </Grid.Row>
                            <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                                <this.ProhibitedCell cell='prohibitedNotes' value={this.state.evidenceInfo.prohibitedNotes} clicked={prohibitedNotesClick}
                                    cellStyle={cellStyle[prohibitedNotesHover]} />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Grid>
                
                <Grid style={{padding: '2rem 1rem', margin: '0rem'}}>
                    <Grid.Column style={{width: '15rem'}}><Header as='h2'>DOCUMENTS ({this.state.files.length})</Header></Grid.Column>
                </Grid>

                <Grid style={{padding: '0rem 0rem 0rem 1.7rem', margin: '0rem'}}>
                    <Grid.Row style={{padding: '1rem 0rem', margin: '0rem'}}>
                        {this.state.files.map((file) => {
                            if(this.getFileEnding(file) === '.doc' || this.getFileEnding(file) === '.docx') {
                                return (
                                    <Grid.Column className="info" style={{width: '15rem'}}>
                                            <img className="cursor-pointer" style={{width: '5rem', height: '5rem'}} src="images/word.png" alt='' onClick={() => this.downloadFile(file)}/>
                                            <p>
                                                Name {this.shortenName(file)}
                                            </p>
                                    </Grid.Column>
                                )
                            } else if(this.getFileEnding(file) === '.xls' || this.getFileEnding(file) === '.xlsx') {
                                return (
                                    <Grid.Column className="info" style={{width: '15rem'}}>
                                            <img className="cursor-pointer" style={{width: '5rem', height: '5rem'}} src="images/excel.png" alt='' onClick={() => this.downloadFile(file)}/>
                                            <p>
                                                Name {this.shortenName(file)}
                                            </p>
                                    </Grid.Column>
                                )
                            } else if(this.getFileEnding(file) === '.ppt' || this.getFileEnding(file) === '.pptx') {
                                return (
                                    <Grid.Column className="info" style={{width: '15rem'}}>
                                            <img className="cursor-pointer" style={{width: '5rem', height: '5rem'}} src="images/powerpoint.png" alt='' onClick={() => this.downloadFile(file)}/>
                                            <p>
                                                Name {this.shortenName(file)}
                                            </p>
                                    </Grid.Column>
                                )
                            } else if(this.getFileEnding(file) === '.pdf') {
                                return (
                                    <Grid.Column className="info" style={{width: '15rem'}}>
                                            <img className="cursor-pointer" style={{width: '5rem', height: '5rem'}} src="images/pdf.png" alt='' onClick={() => this.downloadFile(file)}/>
                                            <p>
                                                Name {this.shortenName(file)}
                                            </p>
                                    </Grid.Column>
                                )
                            }
                        })}
                    </Grid.Row>
                </Grid>
                <Grid style={{padding: '0.5rem 1rem 4rem 2rem'}}>
                    <Grid.Column style={{float: 'right', padding: '0rem 0rem 0rem 1.3rem'}}>
                        <input type="file" id="file" hidden onChange={this.uploadFile} accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf" multiple/>
                        <Button as="label" htmlFor="file" type="button">
                            UPLOAD
                        </Button>
                    </Grid.Column>
                </Grid>
            </div>
        );

    }

    renderNoEvidence() {
        if(this.readOnly) {
            return (    
                <div style={{background: 'white', padding: '1rem', marginTop: '0.2rem'}}>
                    <Grid textAlign='center' style={{margin: '0rem 1rem 0rem 1rem', background: '#F6F7F7', padding: '2rem'}}>
                        <Grid.Row><label style={{color: 'grey'}}>Looks like there is no evidence created for this material</label></Grid.Row>
                    </Grid>
                </div>
            );
        } else {
            return (    
                <div style={{background: 'white', padding: '1rem', marginTop: '0.2rem'}}>
                    <Grid textAlign='center' style={{margin: '0rem 1rem 0rem 1rem', background: '#F6F7F7', padding: '2rem'}}>
                        <Grid.Row><label style={{color: 'grey'}}>Looks like there is no evidence created for this material</label></Grid.Row>
                        <Grid.Row>
                            <Button onClick={() => this.openModal("evidence")}
                            content='CREATE EVIDENCE'/>
                        </Grid.Row>
                    </Grid>
                </div>
            );
        }
    }

    renderLoadingEvidence() {
        return (    
            <div style={{background: 'white', padding: '1rem', marginTop: '0.2rem'}}>
                <Grid textAlign='center' style={{margin: '0rem 1rem 0rem 1rem', background: '#F6F7F7', padding: '2rem'}}>
                    <Grid.Row><div className="no-data">
                    Loading...
                </div></Grid.Row>
                </Grid>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.state.view}
                <CreateEvidenceModal isOpen={this.state.evidenceModalOpen} closeModal={this.closeModal} materialId={this.materialId} getEvidence={this.getEvidence}/>
            </div>
        );
    }
}
export default Evidence;
