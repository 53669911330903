import React, { useState } from "react";
import { Button, Form, Message } from 'semantic-ui-react';
import {
  CognitoUserPool,
  CognitoUser
} from "amazon-cognito-identity-js";

import * as settings from '../settings';

const ResetPassword = props => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const [nextStep, setNextStep] = useState(false);

  const userPool = new CognitoUserPool(settings.login_pool_data);

  var userData = {
    Username: username.toLowerCase(),
    Pool: userPool
  };

  var cognitoUser = new CognitoUser(userData);

  const requestPassword = event => {
    event.preventDefault();
    props.handler('RESET PASSWORD - STEP 2')
    cognitoUser.forgotPassword({
      onSuccess: function (result) {
        setNextStep(true);
        console.log(result);
      },
      onFailure: function (err) {
        console.log(err)
        setUsernameError("Please enter username");
      }
    });
  };

  const confirmPassword = event => {
    event.preventDefault();
    let continueWithConfirm = true;
    if (
      (!hasLowerCase(password) && hasUpperCase(password)) ||
      (hasLowerCase(password) && !hasUpperCase(password))
    ) {
      setPasswordError(
        "Password has to contain both uppercase and lowercase characters."
      );
      continueWithConfirm = false;
    }

    if (password.length < 8) {
      setPasswordError("Password has to be atleast 8 characters.");
      continueWithConfirm = false;
    }

    if (!/\d/.test(password)) {
      setPasswordError("Password has to contain numbers.");
      continueWithConfirm = false;
    }

    if (continueWithConfirm) {
      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(code, password, {
          onFailure(err) {
            reject(err);
            console.log(err)
            setCodeError("Invalid verification code provided, please try again.")
          },
          onSuccess() {
            resolve();
            //Change to routing in future
            window.location.reload();
          },
        });
      });
    }
  };

  const hasLowerCase = str => {
    return /[a-z]/.test(str);
  };

  const hasUpperCase = str => {
    return /[A-Z]/.test(str);
  };

  const setUserNameText = event => {
    setUsername(event.target.value);
  };


  return (
    <div>
      {!nextStep && (
        <Form>
          <Message>
            <Message.Content>
              Please enter your username. You will then get a code in your email, this code has to be entered on the next page.
      </Message.Content>
          </Message>
          <Form.Field>
            <Form.Input
              value={username}
              onChange={event => setUserNameText(event)}
              error={usernameError}
              fluid
              required
              placeholder='Username' />
          </Form.Field>
          <Button type='submit' onClick={requestPassword}>Send</Button>

        </Form>
      )}
      {nextStep && (
        <Form>
          <Message>
            <Message.Content>
              You should recieve a code in your registered email-address. Please check your spam-folder if you can't find it.
         </Message.Content>
            <Message.Content>
              Enter the code below and choose a new password.
         </Message.Content>
          </Message>
          <Form.Field>
            <Form.Input
              value={code}
              onChange={event => setCode(event.target.value)}
              fluid
              error={codeError}
              required
              placeholder='Enter Code' />
          </Form.Field>

          <Form.Field>
            <Form.Input
              value={password}
              onChange={event => setPassword(event.target.value)}
              fluid
              required
              error={passwordError}
              type="password"
              placeholder='Enter Password' />
          </Form.Field>
          <Button type='submit' onClick={confirmPassword}>Send</Button>

        </Form>
      )}
    </div>
  );
};

export default ResetPassword;
