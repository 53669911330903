import * as AWS from 'aws-sdk';
import * as settings from '../../../settings';

class Storage {
    constructor() {
        this.s3 = new AWS.S3({
            accessKeyId: settings.s3.id,
            secretAccessKey: settings.s3.secret,
            region: settings.s3.region
        });
    }

    async uploadFile(file) {
        const params = {
            Bucket: settings.s3.bucket,
            Key: file.name,
            Body: file.content
        };
        
        const stored = await this.s3.upload(params).promise();
        console.log('File uploaded successfully. ' + JSON.stringify(stored));
    }

    downloadFile = (materialId, file) => {
        const params = {
            Bucket: settings.s3.bucket,
            Key: materialId + '_' + file
        };
        this.s3.getObject(params, (err, data) => {
            if (err) {
                console.error(err);
            } else {
                let blob=new Blob([data.Body], {type: data.ContentType});
                let link=document.createElement('a');
                link.href=window.URL.createObjectURL(blob);
                link.download=file;
                link.click();
            }
        });
    }

    listFiles = (materialId, callback) => {
        var params = { 
            Bucket: settings.s3.bucket
        }

        this.s3.listObjects(params, function (err, data) {
            if(err) {
                callback(err);
            } else {
                let result = [];
                for(let i = 0; i < data.Contents.length; i++) {
                    if(data.Contents[i].Key.startsWith(materialId)) {
                        let file = data.Contents[i].Key;
                        file = file.replace(materialId, '');
                        result.push(file.substring(1, file.length));
                    }
                }
                callback(null, result);
            }
        });
    }
}
export default Storage;