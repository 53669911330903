import React, {Component} from 'react';
import CardGroup from './CardGroup';

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.attributes = props.attributes;
        this.goToPrePopulatedMaterials = props.goToPrePopulatedMaterials;
    };

    render() {
        return (
            <div>
                <CardGroup attributes={this.attributes} goToPrePopulatedMaterials={this.goToPrePopulatedMaterials}/>
            </div>
        );
    }
}

export default Dashboard;
