import React, {Component} from 'react';
import _ from 'lodash';
import CreateMaterialModal from './modals/CreateMaterialModal';
import CreateVendorModal from './modals/CreateVendorModal';
import Login from './auth/Login';
import Dashboard from './dashboard/Dashboard';
import Materials from './materials/Materials';
import Vendors from './vendors/Vendors';
import { Dropdown, Menu, Segment, Container, Divider } from 'semantic-ui-react';
import axios from 'axios';
import * as jwtDecode from "jwt-decode";
import './App.css';

import * as settings from './settings';

class App extends Component {
    constructor(props) {
        super(props);

        this.activeTab = '#0749bc';
        this.setFactory = this.setFactory.bind(this)
        this.state = {
            factory: ' ',
            materialModalOpen: false,
            vendorModalOpen: false,
            view: <Login setFactory={this.setFactory} goToDashBoard={this.goToDashBoard}/>,
            dashboardActiveColor: this.activeTab,
            materialsActiveColor: '',
            vendorsActiveColor: '',
            displayModalList: false,
            displaySignOut: false,
            createMenu: null
        };
        this.attributes = this.createEmptyAttributesObject();
    };

    componentDidMount() {
        // These lines are here to prevent Back button in browser
        // from throwing the user out of the app.
        // In a future update, react-router could perhaps be a viable solution
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });

        this.getAttributes(null);
    }

    createEmptyAttributesObject = () => {
        return {
            pdivOptions: [],
            materialCategoryOptions: [],
            materialTypeOptions: [
                {key: '',text: '',value: ''}
            ],
            primaryMaterialOptions: [
                {key: '',text: '',value: ''}
            ],
            metalAlloyOptions: [
                {key: '',text: '',value: ''}
            ],
            purchaserOptions: [
                {key: '',text: '',pdiv: '',value: ''}
            ]
        };
    }

    getAttributes = (callback) => {
        this.attributes = this.createEmptyAttributesObject();

        axios.get(settings.backend_url + '/attributes')
            .then(res => {
                const attributes = res.data.data;
                for(let i = 0; i < attributes.pdiv.length; i++) {
                    this.attributes.pdivOptions.push({
                        id: attributes.pdiv[i].id,
                        key: attributes.pdiv[i].name,
                        text: attributes.pdiv[i].name,
                        value: {id: attributes.pdiv[i].id, name: attributes.pdiv[i].name}
                    });
                }
                for(let i = 0; i < attributes.materialCategory.length; i++) {
                    this.attributes.materialCategoryOptions.push({
                        key: attributes.materialCategory[i].name,
                        text: attributes.materialCategory[i].name,
                        value: attributes.materialCategory[i].name
                    });
                }
                for(let i = 0; i < attributes.materialType.length; i++) {
                    this.attributes.materialTypeOptions.push({
                        key: attributes.materialType[i].name,
                        text: attributes.materialType[i].name,
                        value: attributes.materialType[i].name
                    });
                }
                for(let i = 0; i < attributes.primaryMaterial.length; i++) {
                    this.attributes.primaryMaterialOptions.push({
                        key: attributes.primaryMaterial[i].name,
                        text: attributes.primaryMaterial[i].name,
                        value: attributes.primaryMaterial[i].name
                    });
                }
                for(let i = 0; i < attributes.metalAlloy.length; i++) {
                    this.attributes.metalAlloyOptions.push({
                        key: attributes.metalAlloy[i].name,
                        text: attributes.metalAlloy[i].name,
                        value: attributes.metalAlloy[i].name
                    });
                }
                for(let i = 0; i < attributes.purchaser.length; i++) {
                    this.attributes.purchaserOptions.push({
                        key: attributes.purchaser[i].id,
                        text: attributes.purchaser[i].name,
                        pdiv: attributes.purchaser[i].pdiv,
                        value: attributes.purchaser[i].name
                    });
                }
                if(callback) {
                    callback();
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    setFactory = (event) => {
        this.setState({
            factory: event
        });
    }

    goToDashBoard = () => {
        this.setState({
            createMenu: this.renderCreateMenu(),
            view: <Dashboard attributes={_.clone(this.attributes, true)} goToPrePopulatedMaterials={this.goToPrePopulatedMaterials}/>,
            displayModalList: true,
            displaySignOut: true
        });
    }
   
    goToMaterials = (vendorName) => {
        this.setState({
            view: <Materials selectedFilters={{
                supplier: null,
                materialCategory: null,
                materialType: null,
                primaryMaterial: null,
                materialDesignation: null,
                evidenceType: null,
                evidenceStatus: null,
                nextEvidenceRequired: null,
                materialName: null,
                metalAlloy: null
            }} factory={this.state.factory} attributes={_.clone(this.attributes, true)} vendorName={vendorName} materialsList={null}/>,
            dashboardActiveColor: '',
            materialsActiveColor: this.activeTab,
            vendorsActiveColor: ''
        });
    }

    goToVendors = () => {
        this.setState({
            view: <Vendors attributes={_.clone(this.attributes, true)} goToMaterials={this.goToMaterials}/>,
            dashboardActiveColor: '',
            materialsActiveColor: '',
            vendorsActiveColor: this.activeTab
        });
    }

    goToPrePopulatedMaterials = (materialsList) => {
        this.setState({
            view: <Materials selectedFilters={{
                supplier: null,
                materialCategory: null,
                materialType: null,
                primaryMaterial: null,
                materialDesignation: null,
                evidenceType: null,
                evidenceStatus: null,
                nextEvidenceRequired: null,
                materialName: null,
                metalAlloy: null
            }} factory={this.state.factory} attributes={_.clone(this.attributes, true)} vendorName={null} materialsList={materialsList}/>,
            dashboardActiveColor: '',
            materialsActiveColor: this.activeTab,
            vendorsActiveColor: ''
        });
    }
    
    goToFilteredMaterials = (filter) => {
        this.setState({
            view: <Materials factory={this.state.factory} selectedFilters={filter} attributes={_.clone(this.attributes, true)}/>,
            dashboardActiveColor: '',
            materialsActiveColor: this.activeTab,
            vendorsActiveColor: ''
        });
    }

    refreshMaterials = () => {
        var self = this;
        if(this.state.materialsActiveColor) {
            this.getAttributes(function() {
                self.goToDashBoard();
                self.goToMaterials(null);
            });
        } else {
            self.goToDashBoard();
            self.goToMaterials(null);
        }
    }
    
    refreshVendors = () => {
        var self = this;
        if(this.state.vendorsActiveColor) {
            this.getAttributes(function() {
                self.goToDashBoard();
                self.goToVendors();
            });
        } else {
            self.goToDashBoard();
            self.goToVendors();
        }
    }

    openModal = (modal) =>
    {
        if (modal === "material") {
            this.setState({ materialModalOpen: true });
        }
        else if (modal === "vendor") {
            this.setState({ vendorModalOpen: true });
        }
    }
    closeModal = () =>
    {
        this.setState({
            materialModalOpen: false,
            vendorModalOpen: false
        });
    }

    renderCreateMenu = () => {
        let accessToken = localStorage.getItem('vcr-accessToken');
        if(accessToken) {
            var sessionIdInfo = jwtDecode(accessToken);
            if(sessionIdInfo['cognito:groups'].indexOf('vcr-admin') > -1 || sessionIdInfo['cognito:groups'].indexOf('vcr-edit-create') > -1) {
                return (
                    <Dropdown item simple text='CREATE NEW'>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => this.openModal("material")}>
                                <img style={{width: '1rem', verticalAlign: 'inherit'}} src="images/plus-white.png" alt='plus icon' />
                                MATERIAL</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.openModal("vendor")}>
                                <img style={{width: '1rem', verticalAlign: 'inherit'}} src="images/plus-white.png" alt='plus icon' />
                                VENDOR</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    renderHeaderMenu() {
        if(this.state.displayModalList) {
            let accessToken = localStorage.getItem('vcr-accessToken');
            let admin = false;
            if(accessToken) {
                var sessionIdInfo = jwtDecode(accessToken);
                if(sessionIdInfo['cognito:groups'].indexOf('vcr-admin') > -1) {
                    admin = true;
                }
            }
            return (
                <Menu size='huge'>
                    <Menu.Item as='a' header>
                        <img style={{width: "inherit"}} src="images/skf-logo.png" alt='SKF logo' />
                        </Menu.Item>
                    <Menu.Item style={{fontSize: '1.7rem', paddingLeft: '0.8rem'}}>Vendor Compliance Register</Menu.Item>
                    <Menu.Item as='a' position='right' style={{backgroundColor: this.state.dashboardActiveColor}} onClick={
                        () => {
                            this.setState({
                                view: <Dashboard attributes={_.clone(this.attributes, true)} goToPrePopulatedMaterials={this.goToPrePopulatedMaterials}/>,
                                dashboardActiveColor: this.activeTab,
                                materialsActiveColor: '',
                                vendorsActiveColor: ''
                            });
                        }}>
                        DASHBOARD
                    </Menu.Item>
                    <Menu.Item as='a' style={{backgroundColor: this.state.materialsActiveColor}} onClick={
                        () => {
                            this.refreshMaterials();
                        }}>
                        MATERIALS
                    </Menu.Item>
                    <Menu.Item as='a' style={{backgroundColor: this.state.vendorsActiveColor}} onClick={
                        () => {
                            this.refreshVendors();
                        }}>
                        VENDORS
                    </Menu.Item>
                    {this.state.createMenu}
                    <CreateMaterialModal admin={admin} factory={this.state.factory} attributes={_.clone(this.attributes, true)} isOpen={this.state.materialModalOpen} closeModal={this.closeModal} refreshMaterials={this.refreshMaterials}/>
                    <CreateVendorModal admin={admin} factory={this.state.factory} attributes={_.clone(this.attributes, true)} isOpen={this.state.vendorModalOpen} closeModal={this.closeModal} refreshVendors={this.refreshVendors}/>
                </Menu>
            );
        } else {
            return (
                <Menu size='huge'>
                    <Menu.Item as='a' header>
                        <img style={{width: "inherit"}} src="images/skf-logo.png" alt='SKF logo' />
                        </Menu.Item>
                    <Menu.Item style={{fontSize: '1.7rem', paddingLeft: '0.8rem'}}>Vendor Compliance Register</Menu.Item>
                </Menu>
            )
        }
    }

    renderSignOut() {
        if(this.state.displaySignOut) {
            return (
                <div>
                    <a href='/'>Sign Out</a>
                </div>
            )
        } else {
            return (
                <div></div>
            )
        }
    }

    render() {
        return (
            <div>
                <Segment.Group fixed='top'>
                    <Segment
                    style={{ padding: '10px 141px' }}
                    textAlign='right'>
                        {this.renderSignOut()}
                    </Segment>
                    <Segment style={{ background: '#0F58D6', padding: '0px 141px' }}>
                        {this.renderHeaderMenu()}
                    </Segment>
                </Segment.Group>
                <Container>
                    <Divider section hidden />

                    {this.state.view}
                </Container>
            </div>
        );
    }
}

export default App;
