import React, { Component } from 'react';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import axios from 'axios';
import Evidence from './evidence/Evidence';

import * as settings from '../../settings';

class MaterialBody extends Component {
    constructor(props) {
        super(props);

        this.readOnly = props.readOnly;
        this.attributes = props.attributes;

        this.state = {
            materialInfo: props.materialInfo,
            currCellVal:  '',
            updateMaterial: props.updateMaterial,
            filterOptions: {
                materialCategoryOptions: [],
                materialTypeOptions: [],
                primaryMaterialOptions: [],
                metalAlloyOptions: []
            }
        };

        this.populateFilterOptions();
    }

    validateContent = (data) => {
        const jsonRegExp = /\{.*:.*\}/g;
        const wordRegExp = /(?:^|\W)ALL_ATTRIBUTES|AttributeValueList|ComparisonOperator(?:$|\W)/g;
        if (data.match(jsonRegExp) || data.match(wordRegExp)) {
            this.setState({ inputError: 'Invalid input' });
            return false;
        }
        else return true;
    }

    populateFilterOptions = () => {
        let filterOptions = this.state.filterOptions;

        let uniqueMaterialCategoryOptions = [...new Set(this.attributes.materialCategoryOptions.map(x => x.value))];
        let uniqueMaterialTypeOptions = [...new Set(this.attributes.materialTypeOptions.map(x => x.value))];
        let uniquePrimaryMaterialOptions = [...new Set(this.attributes.primaryMaterialOptions.map(x => x.value))];
        let uniqueMetalAlloyOptions = [...new Set(this.attributes.metalAlloyOptions.map(x => x.value))];

        for(let i = 0; i < uniqueMaterialCategoryOptions.length; i++) {

            filterOptions.materialCategoryOptions.push(
                {key: uniqueMaterialCategoryOptions[i], text: uniqueMaterialCategoryOptions[i], value: uniqueMaterialCategoryOptions[i]}
            );
        }

        for(let i = 0; i < uniqueMaterialTypeOptions.length; i++) {
            filterOptions.materialTypeOptions.push(
                {key: uniqueMaterialTypeOptions[i], text: uniqueMaterialTypeOptions[i], value: uniqueMaterialTypeOptions[i]}
            );
        }

        for(let i = 0; i < uniquePrimaryMaterialOptions.length; i++) {
            filterOptions.primaryMaterialOptions.push(
                {key: uniquePrimaryMaterialOptions[i], text: uniquePrimaryMaterialOptions[i], value: uniquePrimaryMaterialOptions[i]}
            );
        }

        for(let i = 0; i < uniqueMetalAlloyOptions.length; i++) {
            filterOptions.metalAlloyOptions.push(
                {key: uniqueMetalAlloyOptions[i], text: uniqueMetalAlloyOptions[i], value: uniqueMetalAlloyOptions[i]}
            );
        }

        this.setState({filterOptions: filterOptions});
    }

    handleSubmit = (event) => {
        if(!this.readOnly) {
            event.preventDefault();
            if (this.validateContent(this.state.currCellVal)) {
                let materialInfo = this.state.materialInfo;
                materialInfo.notes = this.state.currCellVal;
                this.setState({materialInfo: materialInfo},
                    function() {
                        axios.put(settings.backend_url + '/materials?type=header',
                            this.state.materialInfo)
                            .then(res => {
                                this.state.updateMaterial(this.state.materialInfo);
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    });
            }
        }
    };

    handleChange = (event, { name, value }) => {
        if(!this.readOnly) {
            this.setState({ currCellVal: value });
            this.setState({ inputError: false });
        }
    };

    onHover = cell => { this.setState({ hover: cell }); };
    onExitHover = () => { this.setState({ hover: '' }); };
    onClick = (cell, value) => { this.setState({ click: cell, currCellVal: value }); };
    onBlur = (event) => {
        this.setState({ hover: '', click: '' });
        if (this.state.materialInfo.notes !== this.state.currCellVal)
            this.handleSubmit(event);
    };

    MaterialCell = props => {
        if (props.clicked === props.cell && !this.readOnly) {
            return ( <Grid.Column width={props.width}>
                        <Form onSubmit={this.onBlur}>
                            <Form.Input autoFocus
                                        size='large'
                                        name={props.cell}
                                        defaultValue={props.value}
                                        onChange={this.handleChange}
                                        onBlur={this.onBlur} />
                        </Form></Grid.Column> );
        }
        else {
            return ( <Segment padded={true}
                            onMouseEnter={() => {this.onHover(props.cell)}}
                            onMouseLeave={this.onExitHover}
                            onClick={() => this.onClick(props.cell, props.value)}
                            style={props.cellStyle}
                            width={props.width}>
                        {props.value}
                     </Segment> );
        }
    };

    trimOption = (option) => {
        if(!!option && option.length > 21) {
            return option.substring(0, 18) + '...';
        } else {
            return option;
        }
    }

    handleBodySubmit = (materialInfo) => {
        if(!this.readOnly) {
            if(!materialInfo.materialType || materialInfo.materialType.length < 1) {
                materialInfo.materialType = ' ';
            }
            if(!materialInfo.primaryMaterial || materialInfo.primaryMaterial.length < 1) {
                materialInfo.primaryMaterial = ' ';
            }
            if(!materialInfo.metalAlloy || materialInfo.metalAlloy.length < 1) {
                materialInfo.metalAlloy = ' ';
            }

            this.setState({materialInfo: materialInfo},
                function() {
                    axios.put(settings.backend_url + '/materials?type=body',
                        materialInfo)
                        .then(res => {
                            this.state.updateMaterial(this.state.materialInfo);
                        })
                        .catch(err => {
                            console.error(err);
                        });
                });
        }
    };

    handleMaterialCategorySelect = (_e, target) => {
        if(!this.readOnly) {
            let materialInfo = this.state.materialInfo;
            materialInfo.materialCategory = target.value;
            this.handleBodySubmit(materialInfo);
        }
    }

    handleMaterialTypeSelect = (_e, target) => {
        if(!this.readOnly) {
            let materialInfo = this.state.materialInfo;
            materialInfo.materialType = target.value;
            this.handleBodySubmit(materialInfo);
        }
    }

    handlePrimaryMaterialSelect = (_e, target) => {
        if(!this.readOnly) {
            let materialInfo = this.state.materialInfo;
            materialInfo.primaryMaterial = target.value;
            this.handleBodySubmit(materialInfo);
        }
    }

    handleMetalAlloySelect = (_e, target) => {
        if(!this.readOnly) {
            let materialInfo = this.state.materialInfo;
            materialInfo.metalAlloy = target.value;
            this.handleBodySubmit(materialInfo);
        }
    }

    render() {
        const cellStyle = {
            false: {background: '#F6F7F7', fontSize: '1.2rem', height: '200px'},
            true: {background: '#DCE0E1', fontSize: '1.2rem', height: '200px'}
        }

        return (
            <div>
                <Grid columns={2} style={{background: 'white', padding: '2rem 1rem', margin: '0rem'}}>
                    <Grid columns={3} style={{width:'70%'}}>
                        <Grid.Column>
                            <Grid.Row >
                                <Header as='h2'>VENDOR INFO</Header>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column>Vendor</Grid.Column>
                            <Grid.Column>Vendor Material Designation</Grid.Column>
                            <Grid.Column>Original Manufacturer</Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="info-label" style={{paddingTop: '0.1rem'}}>
                            <Grid.Column>{this.state.materialInfo.supplier}</Grid.Column>
                            <Grid.Column>{this.state.materialInfo.supplierDesignation}</Grid.Column>
                            <Grid.Column>{this.state.materialInfo.originalManufacturer}</Grid.Column>    
                        </Grid.Row>
                    </Grid>

                    <Grid columns={4} style={{width:'70%'}}>
                        <Grid.Column style={{marginTop:'1rem'}}>
                            <Grid.Row >
                                <Header as='h2'>MATERIAL</Header>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Row className="info" style={{paddingBottom: '0.1rem'}} >
                            <Grid.Column>Material Category</Grid.Column>
                            <Grid.Column>Material Type</Grid.Column>
                            <Grid.Column>Primary Material</Grid.Column>
                            <Grid.Column>Metal Alloy</Grid.Column>
                        </Grid.Row>

                        <Grid.Row className="info-label" style={{paddingTop: '0.1rem'}}>
                            <Grid.Column>
                                <Form>
                                    <Form.Select style={{marginLeft: '-5px', minWidth: '180px'}}
                                        options={this.state.filterOptions.materialCategoryOptions}
                                        value={this.state.materialInfo.materialCategory}
                                        text={this.trimOption(this.state.materialInfo.materialCategory)}
                                        onChange={this.handleMaterialCategorySelect}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <Form>
                                    <Form.Select style={{marginLeft: '-5px', minWidth: '180px'}}
                                        options={this.state.filterOptions.materialTypeOptions}
                                        value={this.state.materialInfo.materialType}
                                        text={this.trimOption(this.state.materialInfo.materialType)}
                                        onChange={this.handleMaterialTypeSelect}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <Form>
                                    <Form.Select style={{marginLeft: '-5px', minWidth: '180px'}}
                                        options={this.state.filterOptions.primaryMaterialOptions}
                                        value={this.state.materialInfo.primaryMaterial}
                                        text={this.trimOption(this.state.materialInfo.primaryMaterial)}
                                        onChange={this.handlePrimaryMaterialSelect}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <Form>
                                    <Form.Select style={{marginLeft: '-5px', minWidth: '180px'}}
                                        options={this.state.filterOptions.metalAlloyOptions}
                                        value={this.state.materialInfo.metalAlloy}
                                        text={this.trimOption(this.state.materialInfo.metalAlloy)}
                                        onChange={this.handleMetalAlloySelect}
                                    />
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    
                    <Grid style={{marginTop: '-8.2rem', width: '35%'}}>
                        <Grid.Column>
                            <Grid.Row className="info">
                                Material Notes
                            </Grid.Row>
                            <Grid.Row className="notes" style={{marginTop: '1rem'}}>
                                <this.MaterialCell cell='materialNotes' value={this.state.materialInfo.notes} clicked={this.state.click}
                                                cellStyle={cellStyle[this.state.hover==='materialNotes']} />
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Grid>
                <Evidence materialId={this.state.materialInfo.id}/>
            </div>
         );
    }
}

export default MaterialBody;